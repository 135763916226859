import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CBtnList,
  LRestaurant,
  LRestaurantContact,
  CDefinition,
  CFreeCard,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: (
              <>
                RESTAURANT <br className="u_sp" />
                PLAN
              </>
            ),
            sub: (
              <>
                お祝い・記念日 <br className="u_sp" />
                レストランプラン
              </>
            ),
          },
          imgList: [
            {
              img: {
                src: '/assets/images/anniversary/restaurants_plan/kv.png',
              },
              imgSp: {
                src: '/assets/images/anniversary/restaurants_plan/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'お祝い・記念日',
                path: '/anniversary/',
              },
            ],
            current: {
              label: 'お祝い・記念日 レストランプラン',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mtMedium u_mbExLarge">
        <LWrap>
          <p className="c_sectLead">
            レストランでの特別なひとときに、より華を添えるプランをご用意しました。
            <br />
            心を込めたお料理とおもてなしで素敵な時間を演出いたします。
          </p>
          <CSectTitle
            title={{
              ja: 'お祝い・記念日 レストランプラン',
              en: (
                <>
                  RESTAURANT <br className="u_sp" />
                  PLAN
                </>
              ),
            }}
          />
          <CFreeCard
            col={2}
            data={[
              {
                exClass: 'u_mb60',
                img: {
                  src: '/assets/images/anniversary/restaurants_plan/img_plan.png',
                  alt: 'レストランプラン',
                },
                content: (
                  <>
                    <h2 className="c_headingLv5 u_mb20 u_tal">
                      誕生日・記念日
                    </h2>
                    <p className="u_mb40">
                      レストランでの特別なひとときに、より華を添えるプランをご用意しました。
                      <br />
                      心を込めたお料理とおもてなしで素敵な時間を演出いたします。
                    </p>
                    <CDefinition
                      exClass="u_mb20"
                      data={[
                        {
                          title: 'レストラン',
                          text: (
                            <>
                              <p>
                                70F スカイラウンジ「シリウス」
                                <br />
                                68F フレンチレストラン「ル シエール」
                                <br />
                                68F 日本料理「四季亭」
                                <br />
                                68F 中国料理「皇苑」
                                <br />
                                B1F 鉄板焼「よこはま」
                              </p>
                            </>
                          ),
                        },
                      ]}
                    />
                    <CBtnList
                      data={[
                        {
                          label: 'プランを見る ',
                          link: {
                            href: '/anniversary/restaurants_plan/birthday/',
                          },
                        },
                      ]}
                    />
                  </>
                ),
              },
              {
                exClass: 'u_mb60',
                img: {
                  src: '/assets/images/anniversary/restaurants_plan/img_plan02.png',
                  alt: 'レストランプラン',
                },
                content: (
                  <>
                    <h2 className="c_headingLv5 u_mb20 u_tal">
                      プロポーズ・サプライズ
                    </h2>
                    <p className="u_mb40">
                      高層階からの夜景と美食を堪能するレストラン。
                      <br />
                      ロマンティックな雰囲気と心地よいおもてなしで、ラグジュアリーなひとときを。
                    </p>
                    <CDefinition
                      exClass="u_mb20"
                      data={[
                        {
                          title: 'レストラン',
                          text: (
                            <>
                              <p>
                                70F スカイラウンジ「シリウス」
                                <br />
                                68F フレンチレストラン「ル シエール」
                                <br />
                                68F 日本料理「四季亭」
                                <br />
                                68F 中国料理「皇苑」
                                <br />
                                B1F 鉄板焼「よこはま」
                              </p>
                            </>
                          ),
                        },
                      ]}
                    />
                    <CBtnList
                      data={[
                        {
                          label: 'プランを見る ',
                          link: {
                            href: '/anniversary/restaurants_plan/propose/',
                          },
                        },
                      ]}
                    />
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/anniversary/restaurants_plan/img_plan03.png',
                  alt: 'レストランプラン',
                },
                content: (
                  <>
                    <h2 className="c_headingLv5 u_mb20 u_tal">家族のお祝い</h2>
                    <p className="u_mb40">
                      結納（顔合せ）、七五三や長寿のお祝いなど、様々な節目の記念日に。
                      <br />
                      68階からの景色とお料理を、プライベート個室でお楽しみください。
                    </p>
                    <CDefinition
                      exClass="u_mb20"
                      data={[
                        {
                          title: 'レストラン',
                          text: (
                            <>
                              68F フレンチレストラン「ル シエール」
                              <br />
                              68F 日本料理「四季亭」
                              <br />
                              68F 中国料理「皇苑」
                            </>
                          ),
                        },
                      ]}
                    />
                    <CBtnList
                      data={[
                        {
                          label: 'プランを見る ',
                          link: {
                            href: '/anniversary/restaurants_plan/celebration/',
                          },
                        },
                      ]}
                    />
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            exClass="u_mtExLarge"
            data={[
              {
                label: 'お祝い・記念日トップ',
                link: {
                  href: '/anniversary/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_pb0">
        <LRestaurant />
      </section>
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
